import pino, { Logger } from 'pino';
import { createPinoBrowserSend } from './util';

const createProdLogger = (): Logger => {
  const apiUrl = process.env.NEXT_PUBLIC_SPLUNK_HEC_API || '';
  return pino({
    enabled: !(process.env.NEXT_PUBLIC_SPLUNK_LOG_ENABLED === 'false'),
    browser: {
      asObject: true,
      disabled: true,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      write: () => {},
      transmit: {
        level: process.env.NEXT_PUBLIC_SPLUNK_LOG_LEVEL || 'info',
        send: createPinoBrowserSend(apiUrl),
      },
    },
  });
};

export { createProdLogger };
