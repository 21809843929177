import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import { ParsedUrlQuery } from 'querystring';

export type IQueryParams = {
  pn: string;
  utm_source: string;
  utm_medium: string;
  utm_campaign: string;
  utm_term: string;
  utm_content: string;
  _utmz: string;
  atid: string;
  gclid: string;
  fbclid: string;
  psecID: string;
  sID: string;
  paths: string[];
};
export const initialState: IQueryParams = {
  pn: '',
  utm_source: '',
  utm_medium: '',
  utm_campaign: '',
  utm_term: '',
  utm_content: '',
  _utmz: '',
  atid: '',
  gclid: '',
  fbclid: '',
  psecID: '',
  sID: '',
  paths: [],
};

interface PageQuery extends ParsedUrlQuery {
  paths: any;
  [key: string]: string;
}

const queryParamsSlice = createSlice({
  name: 'queryParams',
  initialState,
  reducers: {
    addQuaryParams: (state, action: PayloadAction<PageQuery>) => ({
      ...state,
      ...action.payload,
    }),

    removedQuaryParams: (state, action: PayloadAction<PageQuery>) => {
      if (!isEmpty(action?.payload)) {
        Object.keys(action?.payload).forEach((key: string) => {
          delete (state as any)[key];
        });
      }
    },

    modifiedQuaryParams: (state, action: PayloadAction<PageQuery>) => {
      if (!isEmpty(action.payload) && !isEmpty(action.payload.removableKeys)) {
        ((action?.payload as any)?.removableKeys as string[])?.forEach((key: string) => {
          delete (state as any)[key];
          delete (action.payload as any)[key];
        });
        delete (state as any)['removableKeys'];
        delete (action.payload as any)['removableKeys'];
      }
    },

    resetQueryParams: () => initialState,
  },
});

export const { addQuaryParams, removedQuaryParams, modifiedQuaryParams, resetQueryParams } =
  queryParamsSlice.actions;
export default queryParamsSlice.reducer;
